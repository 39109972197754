<template>
    <p>Student's Home Page</p>
</template>

<script lang="ts" setup>

</script>

<style lang="sccs" scoped>

</style>